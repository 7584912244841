/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivityLogDataAllResponse } from '../models/activity-log-data-all-response';
import { deleteTestData } from '../fn/activity-log-api/delete-test-data';
import { DeleteTestData$Params } from '../fn/activity-log-api/delete-test-data';
import { getEventLog } from '../fn/activity-log-api/get-event-log';
import { GetEventLog$Params } from '../fn/activity-log-api/get-event-log';
import { getLogVersion } from '../fn/activity-log-api/get-log-version';
import { GetLogVersion$Params } from '../fn/activity-log-api/get-log-version';
import { SuccessTestDataDeletionResponse } from '../models/success-test-data-deletion-response';
import { SuccessVersions } from '../models/success-versions';

@Injectable({ providedIn: 'root' })
export class ActivityLogApiApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getEventLog()` */
  static readonly GetEventLogPath = '/api/v2/events';

  /**
   * Retrieves all the Activity log event log related to the user login session, also accepts an optional date range.
   *
   * Retrieves all the Activity log event log related to the user login session, also accepts an optional date range
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventLog()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventLog$Response(params?: GetEventLog$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityLogDataAllResponse>> {
    return getEventLog(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves all the Activity log event log related to the user login session, also accepts an optional date range.
   *
   * Retrieves all the Activity log event log related to the user login session, also accepts an optional date range
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEventLog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventLog(params?: GetEventLog$Params, context?: HttpContext): Observable<ActivityLogDataAllResponse> {
    return this.getEventLog$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityLogDataAllResponse>): ActivityLogDataAllResponse => r.body)
    );
  }

  /** Path part for operation `getLogVersion()` */
  static readonly GetLogVersionPath = '/api/v1/version';

  /**
   * Retrieves version of application or activity log micro service.
   *
   * Retrieves version of application based on appName if not retrives activity micro service version
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogVersion$Response(params?: GetLogVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessVersions>> {
    return getLogVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves version of application or activity log micro service.
   *
   * Retrieves version of application based on appName if not retrives activity micro service version
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLogVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogVersion(params?: GetLogVersion$Params, context?: HttpContext): Observable<SuccessVersions> {
    return this.getLogVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessVersions>): SuccessVersions => r.body)
    );
  }

  /** Path part for operation `deleteTestData()` */
  static readonly DeleteTestDataPath = '/api/v1/api-test-data';

  /**
   * Removes the Integration test data created on integration tests excution.
   *
   * Removes the Integration test data created on integration tests excution
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTestData()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTestData$Response(params?: DeleteTestData$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessTestDataDeletionResponse>> {
    return deleteTestData(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Integration test data created on integration tests excution.
   *
   * Removes the Integration test data created on integration tests excution
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTestData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTestData(params?: DeleteTestData$Params, context?: HttpContext): Observable<SuccessTestDataDeletionResponse> {
    return this.deleteTestData$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessTestDataDeletionResponse>): SuccessTestDataDeletionResponse => r.body)
    );
  }

}
